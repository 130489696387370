/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Pagination,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { usePageContext } from "@root/renderer/usePageContext";
import { useAccountContext } from "@root/stores/accountStore";
import { Coordinate, Event } from "@root/utils/entities";
import { hasErrors } from "@root/utils/functions";
import React, {
  Fragment,
  lazy,
  startTransition,
  useEffect,
  useMemo,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import { ChevronLeft, ChevronRight, LocationOn } from "@mui/icons-material";
import moment from "moment";
import {
  EventPageContext,
  EventPageStore,
  useEventPageContext,
} from "@root/stores/eventPageStore";
import { EventCategories } from "@root/components/common/EventCategories";
import { EventView } from "./components/EventView";
import { eventService } from "@root/services/api/eventService";
import { navigate } from "vike/client/router";
import { LoadingOverlay } from "@root/components/common/LoadingOverlay";
import { APIProvider } from "@vis.gl/react-google-maps";

const Map = lazy(() =>
  import("./components/Map").then((module) => ({ default: module.Map }))
);
const ViewEventModal = lazy(() =>
  import("@root/components/modals/ViewEventModal").then((module) => ({
    default: module.ViewEventModal,
  }))
);

const USA_BOUNDS: {
  northEast: Coordinate;
  southWest: Coordinate;
} = {
  northEast: { latitude: "49.38", longitude: "-66.94" },
  southWest: { latitude: "25.82", longitude: "-124.39" },
};

export const Page = observer(() => {
  const pageContext = usePageContext();
  const accountStore = useAccountContext();

  const store = useMemo(() => new EventPageStore(), []);

  const [locationLoaded, setLocationLoaded] = useState(false);

  useEffect(() => {
    const getPosition = () => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          startTransition(() => {
            accountStore.setUserLocation(
              position.coords.latitude,
              position.coords.longitude
            );
          });
        },
        async (error) => {
          console.error("Error getting geolocation:", error);
          startTransition(() => {
            accountStore.clearUserLocation();
          });
        },
        {
          timeout: 5000,
        }
      );
    };

    const initializePage = () => {
      store.loadCategories();
      getPosition();
      setLocationLoaded(true);
    };

    initializePage();
  }, []);

  useEffect(() => {
    if (!locationLoaded) return;
    if (!accountStore.locationRequested) return;

    const setupMap = (coordinate: Coordinate | undefined) => {
      if (coordinate) {
        startTransition(() => {
          store.searchEvents(coordinate);
        });
      } else {
        startTransition(() => {
          store.setMapBounds(USA_BOUNDS.northEast, USA_BOUNDS.southWest);
        });
      }
    };

    setupMap(accountStore.userLocation);
  }, [locationLoaded, accountStore.locationRequested]);

  useEffect(() => {
    if (pageContext.urlParsed.search.promptLogin) {
      accountStore.authModalStore.openLoginModal(
        pageContext.urlParsed.search.redir
      );
    }

    const eventId = pageContext.urlParsed.search.event;
    if (!eventId || store.isLoading) return;

    const loadEvent = async (id: string) => {
      store.showLoading();

      const response = await eventService.getEvent(id);

      if (hasErrors(response)) {
        await navigate("/", { overwriteLastHistoryEntry: true });
      } else {
        store.showEvent(response.data!.event);
      }

      store.hideLoading();
    };

    loadEvent(eventId);
  }, [pageContext.urlParsed.search]);

  return (
    <APIProvider apiKey={import.meta.env.PUBLIC_ENV__GOOGLE_MAPS_API_KEY}>
      <EventPageContext.Provider value={store}>
        <EventPage />
      </EventPageContext.Provider>
    </APIProvider>
  );
});

const EventPage = observer(() => {
  const eventPageStore = useEventPageContext();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (mounted) return;
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <>
      <LoadingOverlay show={eventPageStore.isLoading} />
      {mediumScreen ? <MobileView /> : <WebView />}
    </>
  );
});

const WebView = observer(() => {
  const eventPageStore = useEventPageContext();
  return (
    <Fragment>
      <EventGrid />
      {eventPageStore.viewModalStore && (
        <React.Suspense>
          <ViewEventModal
            store={eventPageStore.viewModalStore}
            onClose={() => eventPageStore.hideEvent()}
          />
        </React.Suspense>
      )}
    </Fragment>
  );
});

const EventGrid = observer(() => {
  const accountStore = useAccountContext();
  const eventPageStore = useEventPageContext();

  if (!eventPageStore.events) return;

  return (
    <Grid
      container
      sx={{
        padding: 0,
        gap: 0,
        height: "100%",
        flexWrap: "nowrap",
      }}
    >
      <Grid
        container
        item
        xs={3}
        sx={{
          overflowY: "auto",
          padding: "1rem",
        }}
      >
        {eventPageStore.events.length > 0 ? <EventList /> : <EmptyResults />}
      </Grid>
      <Grid
        item
        xs={9}
        sx={{
          padding: 0,
          border: "1px solid transparent",
        }}
      >
        {accountStore.locationRequested && (
          <React.Suspense>
            <Map />
          </React.Suspense>
        )}
      </Grid>
    </Grid>
  );
});

const MobileView = observer(() => {
  const accountStore = useAccountContext();
  const eventPageStore = useEventPageContext();

  return (
    <Fragment>
      {eventPageStore.event && (
        <MobileEventView eventPageStore={eventPageStore} />
      )}

      <Grid
        container
        sx={{
          padding: 0,
          display: eventPageStore.event ? "none" : "grid",
          gap: 0,
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "1rem 1rem 3rem 1rem",
              display: eventPageStore.viewMode === "list" ? "flex" : "none",
            }}
          >
            {eventPageStore.events && eventPageStore.events!.length > 0 ? (
              <EventList />
            ) : (
              <EmptyResults />
            )}
          </Box>
          {accountStore.locationRequested && (
            <React.Suspense>
              <Map />
            </React.Suspense>
          )}
          <MobileToggleButtons />
        </Grid>
      </Grid>
    </Fragment>
  );
});

const MobileEventView = observer(
  ({ eventPageStore }: { eventPageStore: EventPageStore }) => (
    <Stack sx={{ paddingBottom: "2rem" }}>
      <IconButton
        size="large"
        aria-label="back"
        onClick={() => eventPageStore.hideEvent()}
        sx={(theme) => ({
          height: "fit-content",
          color: "black",
          alignSelf: "start",
          [theme.breakpoints.down("md")]: {
            padding: 0,
            margin: "0.5rem 0 0 0",
          },
        })}
      >
        <ChevronLeft sx={{ width: "2.5rem", height: "2.5rem" }} />
      </IconButton>
      <EventView event={eventPageStore.event!} />
    </Stack>
  )
);

const MobileToggleButtons = observer(() => {
  const eventPageStore = useEventPageContext();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "1rem",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <StyledChip
        onClick={(e) => {
          e.preventDefault();
          if (eventPageStore.viewMode === "list") {
            eventPageStore.showMap();
          } else {
            eventPageStore.showList();
          }
        }}
        variant="outlined"
        label={eventPageStore.viewMode === "list" ? "View Map" : "View List"}
        icon={
          eventPageStore.viewMode === "list" ? (
            <LocationOn sx={{ width: "auto", height: "1.25rem" }} />
          ) : (
            <ChevronRight sx={{ width: "auto", height: "1.25rem" }} />
          )
        }
      />
    </Box>
  );
});

const EmptyResults = () => (
  <Box
    sx={{
      display: "block",
      width: "100%",
      padding: "1rem",
      textAlign: "center",
    }}
  >
    <Typography variant="body2">No events were found.</Typography>
  </Box>
);

const EventPagination = observer(() => {
  const accountStore = useAccountContext();
  const eventPageStore = useEventPageContext();
  const { events, paginationStore } = eventPageStore;

  if (!events || events!.length === 0) return undefined;
  return (
    <Pagination
      count={paginationStore.pageCount}
      page={paginationStore.page + 1}
      boundaryCount={0}
      siblingCount={0}
      onChange={async (e, page) => {
        paginationStore.setPage(page - 1);
        await eventPageStore.searchEvents(accountStore.userLocation);
      }}
      color="primary"
      sx={{ display: "flex", justifyContent: "center" }}
    />
  );
});

const EventList = observer(() => {
  const eventPageStore = useEventPageContext();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
      }}
    >
      <Grid container sx={{ py: 0 }} spacing={2}>
        {eventPageStore.events?.map((event, index) => (
          <Grid
            item
            key={`event${index}_${event.id.substring(0, 4)}`}
            xs={smallScreen ? 12 : mediumScreen ? 6 : 12}
          >
            <EventItem {...event} />
          </Grid>
        ))}
      </Grid>
      <EventPagination />
    </Grid>
  );
});

const EventItem = (event: Event) => {
  const eventPageStore = useEventPageContext();
  const start = useMemo(
    () => moment.utc(event.dateRange?.start).local(),
    [event.dateRange?.start]
  );
  const end = useMemo(
    () => moment.utc(event.dateRange?.end).local(),
    [event.dateRange?.end]
  );

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{
          padding: "0 !important",
          border: "1px solid lightgray",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "bold",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1rem",
                }}
              >
                {event.name}
              </Typography>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  fontWeight: 500,
                  fontSize: "0.80rem",
                  lineHeight: "0.80rem",
                  color: theme.palette.info.main,
                })}
              >
                {`Start: ${start.format("MMMM Do, YYYY")} @ ${start.format("h:mm a")}`}
              </Typography>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  fontWeight: 500,
                  fontSize: "0.80rem",
                  lineHeight: "0.80rem",
                  color: theme.palette.info.main,
                })}
              >
                {`End: ${end.format("MMMM Do, YYYY")} @ ${end.format("h:mm a")}`}
              </Typography>
              <Stack direction="column" sx={{ gap: "0.5rem" }}>
                {event.locationName && (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "1rem",
                      fontSize: "0.8rem",
                      color: "#70757a",
                    }}
                  >
                    {event.locationName}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    lineHeight: "1rem",
                    fontSize: "0.8rem",
                    color: "#70757a",
                  }}
                >
                  {event.location?.address?.textDisplay}
                </Typography>
              </Stack>
            </Box>
            {event.bannerImageUrl && (
              <Box
                sx={{
                  display: "flex",
                  width: "5rem",
                  height: "100%",
                }}
              >
                <img
                  src={event.bannerImageUrl}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Box>
            )}
          </Box>
          <EventCategories event={event} />
          <StyledChip
            variant="outlined"
            label="View Event"
            sx={(theme) => ({
              width: "100%",
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            })}
            onClick={() => eventPageStore.showEvent(event)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const StyledChip = styled(Chip)({
  width: "fit-content",
  backgroundColor: "#FFF !important",
  color: "#1C1B1F",
  fontWeight: 500,
  lineHeight: "1.5rem",
  fontSize: "1rem",
  padding: "0.25rem",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 4px 16px",
});
